<template>
  <div>
    <nav class="navbar m-header-navbar navbar navbar-shadow align-items-center navbar-light navbar-expand floating-nav">
      <div class="navbar-container d-flex content align-items-center">
        <ul class="nav navbar-nav d-xl-none text-light">
          <li class="nav-item text-light">
            <router-link to="/app_home" class="nav-link text-light">
              <feather-icon icon="GridIcon" class="mr-50" size='25' />
              Forum LLAJ Provinsi Jawa Tengah
            </router-link>
            <!-- <a href="#" target="_self" class="nav-link text-light">
              
            </a> -->
          </li>
        </ul>

      </div>
    </nav>
  </div>
</template>

<script>
import axios from '@/config/Axios'
import Base from '@/config/Mixins_base'
import {
  BTabs,
  BTab
} from 'bootstrap-vue'
export default {
  components: {
    BTabs,
    BTab,
  },
  data() {
    return {

    }
  },
  mixins: [Base],
  mounted() {


  },
  methods: {
    async xxx() {
      const self = this;
      await axios({
        method: 'PUT',
        url: '',
        data: {

        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.rs = response.data.result;
          //self.rs_level = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
  },
  props: {

  },
  computed: {
    // setTahun: {
    //   get: function () {
    //	 return this.tahun;
    //   },
    //   set: function (newValue) {
    //	 return this.tahun = newValue;
    //   }
    // }, 

  },

}
</script>

<style></style>